<template>
  <div id="main">
    <v-footer min-height="349px" color="#F2FAFF" class="bg-footer">
      <v-container class="container-xl footer-large">
        <v-row>
          <v-col cols="3">
            <p class="footer-title">Company</p>
            <p class="text-medium pointer" @click="about">About</p>
            <p class="text-medium pointer" @click="findUs">Find Us</p>
          </v-col>
          <v-col cols="3">
            <p class="footer-title">Arisan Keluarga Mandiri</p>
            <p class="text-medium pointer" @click="akm">Home</p>
          </v-col>
          <v-col cols="3">
            <p class="footer-title">IT Service</p>
            <p class="text-medium pointer" @click="headwayItSid">
              Implementasi Sistem <br />Informasi Desa
            </p>
          </v-col>
          <v-col cols="3">
            <p class="footer-title">Head Office</p>
            <p class="text-medium pointer" @click="headwayLocation">
              Jl. Ahmad Yani Utara 484, Denpasar, Bali 80115
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7" xl="9" lg="9" md="7">
            <v-img
              :src="require('../assets/logo-reksatech.png')"
              width="120px"
              height="72.98px"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="footer-small mt-8">
        <v-row>
          <v-col cols="12">
            <p class="footer-title">Company</p>

            <div class="mt-4">
              <p class="text-medium text-footer-sm pointer" @click="about">
                About
              </p>
              <p class="text-medium text-footer-sm pointer" @click="findUs">
                Find Us
              </p>
            </div>
          </v-col>
          <v-col cols="12">
            <p class="footer-title">Arisan Keluarga Mandiri</p>

            <div class="mt-4">
              <p
                class="text-medium text-footer-sm pointer"
                @click="akm"
              >
                Home
              </p>
            </div>
          </v-col>
          <v-col cols="12">
            <p class="footer-title">IT Service</p>

            <div class="mt-4">
              <p
                class="text-medium text-footer-sm pointer"
                @click="headwayItSid"
              >
                Implementasi Sistem Informasi Desa
              </p>
            </div>
          </v-col>
          <v-col cols="12">
            <p class="footer-title">Head Office</p>
            <div class="mt-4">
              <p
                class="text-medium text-footer-sm pointer"
                @click="headwayLocation"
              >
                Jl. Ahmad Yani Utara 484, Denpasar, Bali 80115
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row class="hidden-sm-and-down">
          <v-col cols="6" xl="9" lg="9">
            <v-img
              :src="require('../assets/icon/logo_headway_blue.svg')"
              width="64px"
              height="72.98px"
            ></v-img>
          </v-col>
          <v-col cols="6" xl="3" lg="3">
            <p class="footer-title">Find Our Latest News</p>
            <div class="d-inline-flex">
              <v-img
                width="32px"
                height="32px"
                class="mr-3 pointer"
                contain
                :src="require('../assets/icon/instagram.png')"
                @click="headwayInstagram"
              ></v-img>
              <v-img
                width="32px"
                height="32px"
                class="mr-3 pointer"
                contain
                :src="require('../assets/icon/LI-In-Bug.png')"
                @click="headwayLinkedin"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expand: false,
      expand2: false,
      expand3: false,
      expand4: false,
    };
  },

  methods: {
    ex: function() {
      this.expand = !this.expand;
      this.expand2 = false;
      this.expand3 = false;
      this.expand4 = false;
    },
    ex2: function() {
      this.expand = false;
      this.expand2 = !this.expand2;
      this.expand3 = false;
      this.expand4 = false;
    },
    ex3: function() {
      this.expand = false;
      this.expand2 = false;
      this.expand3 = !this.expand3;
      this.expand4 = false;
    },
    ex4: function() {
      this.expand = false;
      this.expand2 = false;
      this.expand3 = false;
      this.expand4 = !this.expand4;
    },
    headwayItDonya() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push("/products/it-service/donya");
    },
    headwayItManpro() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push("/products/it-service/project-management");
    },
    headwayItSid() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push(
        "/products/it-service/implementation-of-sistem-informasi-desa"
      );
    },
    about() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push("/about");
    },
    findUs() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push("/find-us");
    },
    akm: function() {
      window.open("https://akm.or.id/", "_blank");
    },
    headwayTestCertificate: function() {
      window.open("https://headwaytest.com/certificate", "_blank");
    },
    headwayLocation() {
      window.open(
        "https://www.google.com/maps/place/PT.+HEADWAY/@-8.5978297,115.2147183,15z/data=!4m5!3m4!1s0x0:0x42bd7765aa9b98c4!8m2!3d-8.5978297!4d115.2147183",
        "_blank"
      );
    },
    headwayInstagram() {
      window.open("https://www.instagram.com/klikheadway/", "_blank");
    },
    headwayLinkedin() {
      window.open("https://www.linkedin.com/company/headway-corpora", "_blank");
    },
    headwayTwitter() {},
  },
};
</script>

<style scoped>
.footer-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #004d93;
}

.bg-footer {
  height: 349px !important;
}

.footer-small {
  display: none !important;
}

.rotate-180deg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media only screen and (min-width: 1904px) {
  .container-xl {
    padding: 0px 200px;
  }
}

@media only screen and (max-width: 960px) {
  .bg-footer {
    min-height: 830px !important;
  }

  .footer-small {
    display: block !important;
  }

  .footer-large {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .bg-footer {
    min-height: 850px !important;
  }

  .footer-small {
    display: block !important;
  }

  .footer-large {
    display: none !important;
  }
}

@media only screen and (max-width: 360px) {
  .bg-footer {
    min-height: 749px !important;
  }

  .text-footer-sm {
    font-size: 12px;
    line-height: 14px;
  }

  .footer-title {
    font-size: 14px;
    line-height: 14px;
  }

  .icon-footer-sm {
    width: 22px !important;
    height: 22px !important;
  }
}
</style>
