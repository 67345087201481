<template>
  <div id="main">
    <v-app-bar
      app
      flat
      height="110px"
      :class="{ navbarScroll: !topOfPage, navbar: topOfPage, navLarge: true }"
    >
      <v-container class="container-xl" fill-height>
        <v-row align="center" justify="center" align-content="center">
          <v-col cols="2" justify="center" class="pl-5">
            <router-link to="/" class="text-decoration-none">
              <v-img
                contain
                @click="thisHome"
                :src="
                  topOfPage
                    ? require('../assets/logo-reksatech.png')
                    : require('../assets/logo-reksatech.png')
                "
                class="logo-headway ml-8"
              ></v-img>
            </router-link>
          </v-col>

          <v-col cols="8" align="center" align-self="center">
            <ul class="menu">
              <li @click="thisHome">
                <router-link
                  :class="{
                    navTextScroll: !topOfPage,
                    navText: topOfPage,
                    navText: isHome,
                  }"
                  class="text-medium pt-5 pb-2 px-0"
                  style="width: 50px"
                  to="/"
                  >Home</router-link
                >
              </li>
              <li @mouseover="listTwo = true" @mouseleave="listTwo = false">
                <p
                  :class="{
                    navTextScroll: !topOfPage,
                    navText: topOfPage,
                    routerProduct: isProduct,
                  }"
                  class="text-medium pt-5 pb-2 px-0"
                  style="width: 103px"
                >
                  Products
                  <v-icon
                    color="#000000"
                    :class="{
                      navTextScroll: !topOfPage,
                      navText: topOfPage,
                      navTextScroll: !isHome,
                      routerProductSelect: isProduct,
                      'rotate-180': true,
                    }"
                    >mdi-chevron-down</v-icon
                  >
                </p>
                <transition name="fade">
                  <ul v-if="listTwo" @click="listTwo = false" class="sub-menu">
                    <li>
                      <a
                        href="https://akm.or.id/"
                        class="text-left router-text"
                        target="_blank"
                        >AKM</a
                      >
                    </li>
                    <li
                      @mouseover="listFive = true"
                      @mouseleave="listFive = false"
                      @click="notHome"
                    >
                      <router-link
                        class="router-text pt-2 text-left"
                        style="padding-right: 0px"
                        to="/products/it-service"
                        ref="itService"
                        >IT Service
                        <v-icon color="#004D93"
                          >mdi-chevron-right</v-icon
                        ></router-link
                      >
                      <transition name="fade">
                        <ul
                          v-if="listFive"
                          @click="listFive = false"
                          class=".sub-menu2"
                        >
                          <li class="text-left pt-4" @click="notHome">
                            <router-link
                              class="router-text pt-0"
                              to="/products/it-service/implementation-of-sistem-informasi-desa"
                              >Implementation of "Sistem Informasi
                              Desa"</router-link
                            >
                          </li>
                        </ul>
                      </transition>
                    </li>
                  </ul>
                </transition>
              </li>
              <li @click="notHome">
                <router-link
                  :class="{ navTextScroll: !topOfPage, navText: topOfPage }"
                  class="text-medium pt-5 pb-2 px-0"
                  style="width: 51px"
                  to="/about"
                  >About</router-link
                >
              </li>
              <li @click="notHome">
                <router-link
                  :class="{ navTextScroll: !topOfPage, navText: topOfPage }"
                  class="text-medium pt-5 pb-2 px-0"
                  style="width: 70px"
                  to="/find-us"
                  >Find Us</router-link
                >
              </li>
            </ul>
          </v-col>

          <v-col cols="2" align="center" class="pt-1">
            <v-btn
              :class="{
                'btn-primary': !topOfPage,
                'btn-secondary': topOfPage,
                'btn-contact-navbar': true,
              }"
              elevation="0"
              @click="openChat()"
              >Contact us</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar
      app
      :class="{
        navbarScroll: !topOfPage,
        navbar: topOfPage,
        navSmall: true,
        navBorder: !topOfPage && !navOverlay,
      }"
      flat
      height="70px"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="2" sm="1" md="1">
            <router-link to="/">
              <v-img
                class="hidden-sm-and-up"
                @click="backHomeMobile"
                contain
                :src="require('../assets/logo-reksatech.png')"
                :aspect-ratio="1 / 1"
                height="50px"
              ></v-img>
              <v-img
                class="d-none d-sm-flex"
                @click="backHomeMobile"
                contain
                :src="require('../assets/logo-reksatech.png')"
                :aspect-ratio="1 / 1"
                height="100px"
              ></v-img>
            </router-link>
          </v-col>
          <v-col cols="8" sm="10" md="10" class="my-auto">
            <p
              class="text-logo mb-0"
              :class="{ navText: topOfPage, 'text-logo-scroll': !topOfPage }"
            >
              REKSA
            </p>
          </v-col>
          <v-col cols="2" sm="1" md="1" align="right" class="my-auto">
            <v-icon
              :class="{
                navText: topOfPage,
                'text-logo-scroll': !topOfPage,
                'rotate-90': navOverlay,
              }"
              @click="showNavMobile"
            >
              {{ navOverlay ? 'mdi-close' : 'mdi-menu' }}
            </v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-overlay :value="navOverlay" :z-index="zIndex">
      <v-expand-transition>
        <v-card class="card-nav-menu" v-show="expandNavMobile" light>
          <v-card-text class="pt-5 pb-7 px-0">
            <v-row class="d-block">
              <!-- <router-link to="/" class="text-decoration-none">
                <v-col cols="12">
                  <p class="text-medium text-lg color--black mb-0">Home</p>
                </v-col>
              </router-link> -->
              <v-col
                cols="12"
                class="pb-0"
                @click="expandNavProduct = !expandNavProduct"
              >
                <p class="text-medium text-lg color--black mb-3 px-6">
                  Products
                  <v-icon
                    class="float-right color--black"
                    :class="{ 'rotate-180deg': expandNavProduct }"
                    >mdi-chevron-down</v-icon
                  >
                </p>
                <v-expand-transition>
                  <div class="border-top-bottom" v-show="expandNavProduct">
                    <div class="my-5 mx-6" @click="headwayTest">
                      <p class="text-semibold text-md mb-3 color--primary">
                        AKM
                      </p>
                      <v-img
                        contain
                        :src="require('../assets/logo-akm.png')"
                        class="mb-3 test-logo"
                      ></v-img>
                      <p class="text-sm color--black text-lh-15">
                        A valid and honest multi-field competency testing system
                        to measure your ability.
                      </p>
                    </div>
                    <div class="mx-6 mb-5">
                      <p class="text-semibold text-md">IT SERVICE</p>
                      <router-link
                        class="text-decoration-none"
                        to="/products/it-service/implementation-of-sistem-informasi-desa"
                      >
                        <v-row @click="goPageMobile">
                          <v-col cols="10" sm="11">
                            <p
                              class="text-md text-semibold color--primary mb-1"
                            >
                              Implementation of "Sistem Informasi Desa"
                            </p>
                            <p class="text-sm color--black mb-0 text-lh-15">
                              Organize the structure data of communities to
                              providing best services to public.
                            </p>
                          </v-col>
                        </v-row>
                      </router-link>
                    </div>
                  </div>
                </v-expand-transition>
              </v-col>
              <router-link class="text-decoration-none" to="/about">
                <v-col cols="12" class="px-9" @click="goPageMobile">
                  <p class="text-medium text-lg color--black mb-0">About</p>
                </v-col>
              </router-link>
              <router-link class="text-decoration-none" to="/find-us">
                <v-col cols="12" class="px-9" @click="goPageMobile">
                  <p class="text-medium text-lg color--black mb-0">Find Us</p>
                </v-col>
              </router-link>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listTwo: false,
      listFive: false,
      topOfPage: true,
      isHome: true,
      page: "page",
      isProduct: false,
      expandNavMobile: false,
      navOverlay: false,
      expandNavProduct: false,
      zIndex: 997,
      navMobile: 1,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    if (this.$route.name != "Home") {
      this.isHome = false;
      this.notHome();
    } else {
      this.isHome = true;
      this.thisHome();
    }
  },

  mounted() {
    this.$root.$on("thisHomeNavbar", () => {
      this.notHome();
    });
    this.$root.$on("notHomeNavbar", () => {
      this.notHome();
    });
  },

  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (window.scrollY > 10 && this.isHome == true) {
        return (this.topOfPage = false);
      }
      if (window.scrollY <= 10 && this.isHome == true) {
        if (!this.topOfPage) {
          return (this.topOfPage = true);
        }
      }
    },

    notHome: function () {
      this.topOfPage = false;
      this.isHome = false;
      if (
        this.$route.name == "Headway IT Service" ||
        this.$route.name == "Donya" ||
        this.$route.name == "Project Management" ||
        this.$route.name == "SID"
      ) {
        this.isProduct = true;
      } else {
        this.isProduct = false;
      }
    },

    thisHome: function () {
      this.topOfPage = true;
      this.isHome = true;
      this.isProduct = false;
    },

    backHomeMobile: function () {
      this.thisHome();
      this.navOverlay = false;
      this.expandNavMobile = false;
    },

    goPageMobile: function () {
      this.notHome();
      this.navOverlay = false;
      this.expandNavMobile = false;
    },

    showNavMobile: function () {
      if (window.scrollY < 10 && this.isHome == true) {
        var top = true;
      }
      if (this.isHome == true) {
        if (top == true) {
          this.topOfPage = !this.topOfPage;
        }
      }
      this.navOverlay = !this.navOverlay;
      setTimeout(() => (this.expandNavMobile = !this.expandNavMobile), 100);
    },

    headwayTest: function () {
      this.navOverlay = false;
      this.expandNavMobile = false;
      window.open("https://headwaytest.com/", "_blank");
    },

    openChat() {
      document.querySelector(".woot--bubble-holder").click();
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
@import "../styles/css/style.css";

.logo-headway {
  height: 77.71px;
  width: 64px;
}

.btn-primary {
  font-weight: 500 !important;
}

.btn-contact-navbar {
  font-weight: 500 !important;
  font-size: 14px !important;
  width: 130px !important;
  height: 40px !important;
  letter-spacing: 0px !important;
}

.navbar .router-link-exact-active {
  border-bottom: 2px solid white;
  background: transparent !important;
}

.navbarScroll .router-link-exact-active {
  font-weight: 600 !important;
  color: #0369b3 !important;
  border-bottom: 2px solid #0369b3 !important;
  background: transparent !important;
}

.sub-menu .router-link-exact-active {
  border-bottom: none !important;
  color: #0369b3 !important;
}

.menu li ul li ul .router-link-exact-active {
  border-bottom: none !important;
  color: #0369b3 !important;
}

.menu {
  list-style: none;
}

.menu a {
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.menu li {
  display: block;
  float: left;
  position: relative;
  background: transparent;
  color: #000000;
  min-width: 170px;
}

.menu li:hover .rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.menu li ul li:hover .rotate-180deg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sub-menu {
  position: absolute;
  padding: 0;
  left: 10px;
  top: 50px;
  background: #f2faff;
  border-radius: 16px !important;
  width: 207px !important;
  min-height: 106px !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important;
  z-index: 1000;
}

.navbarScroll .sub-menu {
  background: #ffffff;
}

.menu li ul li ul {
  position: absolute;
  left: 215px;
  top: -58px;
  background: #f2faff;
  border-radius: 16px !important;
  padding-left: 0px;
  width: 207px !important;
  min-height: 106px !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important;
  z-index: 1000;
}

.navbarScroll .menu li ul li ul {
  background: #ffffff;
}

.menu li ul li {
  background: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.router-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #004d93 !important;
}

.router-text:hover {
  color: #0369b3 !important;
}

.navbar {
  background: transparent !important;
}

.navText {
  color: #ffffff !important;
}

.navTextScroll {
  color: #000000 !important;
}

.text-logo-scroll {
  color: #0369b3 !important;
}

.navbarScroll {
  background: white !important;
}

.routerProduct {
  font-weight: 600 !important;
  color: #0369b3 !important;
  border-bottom: 2px solid #0369b3;
  background: transparent !important;
}

.routerProductSelect {
  color: #0369b3 !important;
}

.btn-secondary {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  background: transparent !important;
  font-weight: 500 !important;
}

.btn-secondary:hover {
  color: #0369b3 !important;
  background: white !important;
}

.navLarge {
  display: block;
}

.navSmall {
  display: none;
}

.text-logo {
  font-family: Nunito;
  font-weight: 800;
  font-size: 20px;
  color: #0369b3;
}

.border-top-bottom {
  border-top: 1px solid #0000001a !important;
  border-bottom: 1px solid #0000001a !important;
}

div >>> .v-overlay__content {
  position: absolute;
  top: 69px;
  width: 100%;
  border-radius: 20px !important;
}

.card-nav-menu {
  background: #ffffff !important;
  border-radius: 0 0 20px 20px !important;
}

.test-logo {
  max-width: 25%;
  margin-left: -10px;
}

.text-lh-15 {
  line-height: 17px;
}

@media only screen and (min-width: 1904px) {
  .container-xl {
    padding: 0px 200px;
  }
}

@media only screen and (max-width: 1264px) {
  .menu li {
    min-width: 130px !important;
  }
}

@media only screen and (max-width: 960px) {
  .navLarge {
    display: none;
  }

  .navSmall {
    display: block;
    z-index: 998 !important;
  }

  .navBorder {
    border-bottom: 1px solid #eaeaea !important;
  }
}

@media only screen and (max-width: 885px) {
  .menu li {
    min-width: 100px !important;
  }

  .menu a {
    font-size: 15px;
  }

  .menu p {
    font-size: 15px;
  }

  .logo-headway {
    height: 67px;
    width: 54px;
  }

  .btn-contact-navbar {
    font-weight: 500 !important;
    font-size: 12px !important;
    width: 110px !important;
    height: 40px !important;
  }

  div >>> .v-list-item__title {
    font-weight: 500 !important;
  }
}

@media only screen and (max-width: 600px) {
  .test-logo {
    max-width: 50vw;
    margin-left: -8px;
  }

  .text-logo {
    text-align: center;
  }
}
</style>
