<template>
  <div id="main" v-if="info != null">
    <v-img
      :src="require('../assets/background/Rectangle 43.svg')"
      class="bg_home d-none d-sm-flex"
    >
      <v-container fluid class="container-xl header-content">
        <v-container>
          <v-img
            class="img-header hidden-sm-and-down"
            contain
            :aspect-ratio="1 / 1"
            :src="require('../assets/icon/header_icon.svg')"
          ></v-img>
          <v-row class="row-header">
            <v-col cols="12" lg="12" md="12" xl="10" xs="12" sm="12">
              <p class="header-title">
                "Empowering Growth through Digital Solutions"
              </p>
            </v-col>
            <v-col cols="12" xl="10" xs="12" md="12" lg="11" sm="12">
              <p
                class="text-lg text-regular text-sub-header color--white"
              >
                Komitmen untuk menghadirkan solusi-solusi digital yang tangguh dan dapat diandalkan.
                <br />
                <br />
                ReksaTech by CV Reksa 
              </p>
            </v-col>

            <v-col cols="12" xl="11" sm="12" class="btn-center">
              <v-btn class="btn-primary btn-xl mt-8 mt-md-0" @click="openChat()"
                >Contact us</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-img>

    <v-img
      :src="require('../assets/background/bg_home_sm.svg')"
      class="bg_home hidden-sm-and-up"
    >
      <v-container fluid class="container-xl header-content">
        <v-container>
          <v-img
            class="img-header hidden-sm-and-down"
            contain
            :aspect-ratio="1 / 1"
            :src="require('../assets/icon/header_icon.svg')"
          ></v-img>
          <v-row class="row-header">
            <v-col cols="12" lg="12" md="12" xl="10" xs="12" sm="12">
              <p class="header-title mb-0">
                "Empowering Growth through Digital Solutions"
              </p>
            </v-col>
            <v-col cols="12" xl="10" xs="12" md="12" lg="11" sm="12">
              <p
                class="text-lg text-regular text-sub-header color--white"
              >
                Komitmen untuk menghadirkan solusi-solusi digital yang tangguh dan dapat diandalkan.
                <br />
                <br />
                ReksaTech by CV Reksa
              </p>
            </v-col>

            <v-col cols="12" xl="11" sm="12" class="btn-center">
              <v-btn class="btn-primary btn-xl mt-8 mt-md-0">Contact us</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-img>

    <v-container class="container-xl product-large">
      <v-row>
        <v-col cols="12" class="mb-10"
          ><p class="sub-header-title text-center mb-0">Our Products</p></v-col
        >
        <v-col cols="6" align="center">
          <v-img
            contain
            :aspect-ratio="20 / 2"
            :src="require('../assets/logo-akm.png')"
          ></v-img>
        </v-col>
        <v-col cols="6" align="center">
          <v-img
            contain
            :aspect-ratio="16 / 2"
            :src="require('../assets/logo-it.png')"
          ></v-img>
        </v-col>

        <v-col cols="6" class="text-center px-10 pt-6 line-right">
          <p
            class="text-regular text-lg"
            style="line-height: 27.61px"
          >
            Sebuah inovasi manajemen kegiatan arisan yang bermanfaat dalam pengelolaan komunitas arisan secara praktis tanpa ribet, lebih transparan dan akuntabel. 
          </p>
        </v-col>
        <v-col cols="6" class="text-center px-11 pt-6 line-left">
          <p
            class="text-regular text-lg"
            style="line-height: 27.61px"
          >
            Memberikan solusi yang inovatif dan efektif bagi klien dalam mencapai pertumbuhan yang berkelanjutan. Merancang, mengembangkan, dan menyempurnakan solusi teknologi yang menguntungkan bagi perkembangan bisnis Anda
          </p>
        </v-col>
        <v-col cols="6" class="text-center px-10 mt-4">
          <v-btn class="btn-primary" elevation="0" @click="headwayTest"
            >Learn more</v-btn
          >
        </v-col>
        <v-col cols="6" class="text-center px-11 mt-4">
          <v-btn class="btn-primary" elevation="0" @click="headwayIt"
            >Learn more</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-container class="container-xl product-small">
      <v-row>
        <v-col cols="12" class="mb-3 mb-md-10 mb-sm-10"
          ><p class="sub-header-title text-center mb-0">Our Products</p></v-col
        >
        <v-col cols="12" align="center">
          <v-img
            contain
            :aspect-ratio="20 / 2"
            :src="require('../assets/logo-akm.png')"
          ></v-img>
        </v-col>

        <v-col cols="12" class="text-center px-6 pt-0 px-sm-16 pt-sm-6">
          <p
            class="text-regular text-lg mx-10 product-desc-xsmall"
          >
            Sebuah inovasi manajemen kegiatan arisan yang bermanfaat dalam pengelolaan komunitas arisan secara praktis tanpa ribet, lebih transparan dan akuntabel.
          </p>
        </v-col>

        <v-col cols="12" class="text-center px-10 mt-0 mt-sm-4">
          <v-btn
            class="btn-primary btn-learn-more"
            elevation="0"
            @click="akm"
            >Learn more</v-btn
          >
        </v-col>

        <v-col cols="12" align="center" class="mt-9">
          <v-img
            contain
            :aspect-ratio="12 / 2"
            :src="require('../assets/logo-it.png')"
          ></v-img>
        </v-col>
        <v-col cols="12" class="text-center px-6 pt-0 px-sm-16 pt-sm-6">
          <p
            class="text-regular text-lg mx-10 product-desc-xsmall"
          >
            Memberikan solusi yang inovatif dan efektif bagi klien dalam mencapai pertumbuhan yang berkelanjutan. Merancang, mengembangkan, dan menyempurnakan solusi teknologi yang menguntungkan bagi perkembangan bisnis Anda
          </p>
        </v-col>

        <v-col cols="12" class="text-center px-11 mt-0 mt-sm-4">
          <v-btn
            class="btn-primary btn-learn-more"
            elevation="0"
            @click="headwayIt"
            >Learn more</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-img
      :src="require('../assets/background/Rectangle 46.svg')"
      width="100%"
      class="bg-our-former mt-16 mb-16"
    >
      <v-container fluid class="px-0 my-auto">
        <p class="sub-header-title former-spacer text-center mt-16 pt-16">
          Our Former Clients
        </p>
        <VueSlickCarousel v-bind="customer">
          <div v-for="ico in ourFormerData" :key="ico.index" class="text-left">
            <v-img
              contain
              height="135px"
              width="135px"
              :aspect-ratio="1 / 1"
              class="img-former"
              :src="
                'https://klikheadway.si-hadir.com/our-former-client/' + ico.logo
              "
            ></v-img>
          </div>
        </VueSlickCarousel>
      </v-container>
    </v-img>

    <v-container fluid class="test-space">
      <v-row class="pa-0 mb-16">
        <v-col cols="12" class="text-center pa-0 mt-0">
          <p class="sub-header-title mb-sm-14 mb-4">Witness what people say</p>
          <v-container fluid class="pr-0 pl-0">
            <v-slide-group height="219" class="d-none d-md-flex">
              <template v-slot:prev="{ on, attrs }">
                <v-btn
                  fab
                  class="btn-arrow-testi mr-n5"
                  color="#004D93"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-arrow-left</v-icon></v-btn
                >
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-btn
                  fab
                  color="#004D93"
                  class="btn-arrow-testi ml-n5"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </template>
              <v-slide-item v-for="data in testiData" :key="data" class="px-6">
                <v-card
                  flat
                  class="bg3 py-8 px-8 mx-4"
                  rounded="xl"
                  max-width="652px"
                  height="232px"
                >
                  <v-row>
                    <v-col cols="4">
                      <v-img
                        :aspect-ratio="1 / 1"
                        max-width="155px"
                        max-height="155px"
                        contain
                        class="rounded-xl"
                        :src="
                          'https://www.klikheadway.si-hadir.com/love-from-customer/' +
                          data.photo
                        "
                      ></v-img>
                    </v-col>
                    <v-col cols="8" class="text-left">
                      <p class="testi-title mb-1" v-text="data.name"></p>
                      <p class="testi-subtitle" v-text="data.position"></p>
                      <p
                        class="text-regular testi-text"
                        v-text="data.description"
                      ></p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>

            <v-carousel
              hide-delimiters
              show-arrows
              class="d-none d-sm-block d-md-none carousel-testi-small"
            >
              <template v-slot:prev="{ on, attrs }">
                <v-btn
                  fab
                  class="btn-arrow-testi"
                  color="#004D93"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-arrow-left</v-icon></v-btn
                >
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-btn
                  fab
                  color="#004D93"
                  class="btn-arrow-testi"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </template>
              <v-carousel-item
                v-for="data in testiData"
                :key="data"
                class="px-6"
              >
                <v-row class="p-0 m-0">
                  <v-col cols="12" class="p-0">
                    <v-card
                      flat
                      class="bg3 py-8 px-8 mx-auto customer-card-small"
                      rounded="xl"
                      max-width="652px"
                      height="200px"
                    >
                      <v-row>
                        <v-col cols="4">
                          <v-img
                            :aspect-ratio="1 / 1"
                            max-width="150px"
                            max-height="150px"
                            contain
                            class="rounded-xl"
                            :src="
                              'https://www.klikheadway.si-hadir.com/love-from-customer/' +
                              data.photo
                            "
                          ></v-img>
                        </v-col>
                        <v-col cols="8" class="text-left">
                          <p class="testi-title mb-1" v-text="data.name"></p>
                          <p class="testi-subtitle" v-text="data.position"></p>
                          <p
                            class="text-regular testi-text"
                            v-text="data.description"
                          ></p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>

            <VueSlickCarousel v-bind="testi" class="hidden-sm-and-up">
              <div v-for="data in testiData" :key="data">
                <v-card flat class="bg3 py-4 px-4 mx-2" rounded="xl">
                  <v-row justify="center" align="center">
                    <v-col cols="12" class="justify-content-center">
                      <v-img
                        :aspect-ratio="1 / 1"
                        max-width="110px"
                        class="rounded-lg mx-auto"
                        :src="
                          'https://www.klikheadway.si-hadir.com/love-from-customer/' +
                          data.photo
                        "
                      ></v-img>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <p class="testi-title mb-n1" v-text="data.name"></p>
                      <p class="testi-subtitle" v-text="data.position"></p>
                      <p
                        class="text-regular testi-text"
                        v-text="data.description"
                      ></p>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </VueSlickCarousel>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-img
      :src="require('../assets/background/Rectangle 42.png')"
      class="bg4 mb-16 mt-16"
    >
      <v-container fill-height class="container-xl container-sm">
        <v-row class="" justify="center" align="center">
          <v-col cols="12" lg="7" xl="7" md="12" sm="12">
            <h3 class="color--white">Have any questions?</h3>
            <h3 class="color--white">
              We are here to help <br />
              you.
            </h3>
            <p class="text-lg text-regular mt-10 text-question color--white">
              Ask us about your questions about our products. Our team will be
              happy to help you.
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="5"
            xl="5"
            md="12"
            sm="12"
            xs="12"
            fill-height
            align="center"
          >
            <v-btn
              elevation="0"
              class="btn-lg btn-contact float-right"
              @click="openChat()"
              >Contact Us</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Vue from "vue";
import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

export default {
  name: "Home",
  components: {
    VueSlickCarousel,
    Footer,
  },

  data() {
    return {
      info: null,
      ourFormerData: null,
      testiData: null,
      testiModel: 0,
      customer: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        draggable: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            },
          },

          {
            breakpoint: 960,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },

      testi: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },

      icons: [
        {
          index: 1,
          width: "135px",
          height: "135px",
          path: "icon (1).png",
        },
        {
          index: 2,
          width: "135px",
          height: "135px",
          path: "icon (2).png",
        },
        {
          index: 3,
          width: "135px",
          height: "135px",
          path: "icon (3).png",
        },
        {
          index: 4,
          width: "135px",
          height: "135px",
          path: "icon (4).png",
        },
        {
          index: 5,
          width: "135px",
          height: "135px",
          path: "icon (5).png",
        },
        {
          index: 6,
          width: "135px",
          height: "135px",
          path: "icon (6).png",
        },
        {
          index: 7,
          width: "135px",
          height: "135px",
          path: "icon (7).png",
        },
        {
          index: 8,
          width: "135px",
          height: "135px",
          path: "icon (8).png",
        },
        {
          index: 9,
          width: "135px",
          height: "135px",
          path: "icon (9).png",
        },
        {
          index: 10,
          width: "135px",
          height: "135px",
          path: "icon (10).png",
        },
        {
          index: 11,
          width: "135px",
          height: "96px",
          path: "icon (11).png",
        },
        {
          index: 12,
          width: "135px",
          height: "135px",
          path: "icon (12).png",
        },
        {
          index: 13,
          width: "135px",
          height: "135px",
          path: "icon (13).png",
        },
        {
          index: 14,
          width: "135px",
          height: "135px",
          path: "icon (14).png",
        },
        {
          index: 15,
          width: "135px",
          height: "135px",
          path: "icon (15).png",
        },
        {
          index: 16,
          width: "135px",
          height: "135px",
          path: "icon (16).png",
        },
        {
          index: 17,
          width: "132px",
          height: "135px",
          path: "icon (17).png",
        },
        {
          index: 18,
          width: "167px",
          height: "135px",
          path: "icon (18).png",
        },
        {
          index: 19,
          width: "135px",
          height: "135px",
          path: "icon (19).png",
        },
        {
          index: 20,
          width: "135px",
          height: "135px",
          path: "icon (20).png",
        },
        {
          index: 21,
          width: "135px",
          height: "135px",
          path: "icon (21).png",
        },
        {
          index: 22,
          width: "135px",
          height: "135px",
          path: "icon (22).png",
        },
        {
          index: 23,
          width: "135px",
          height: "135px",
          path: "icon (23).png",
        },
        {
          index: 24,
          width: "135px",
          height: "135px",
          path: "icon (24).png",
        },
        {
          index: 25,
          width: "135px",
          height: "135px",
          path: "icon (25).png",
        },
      ],
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  mounted() {
    this.getHomeData();
    this.getFormerData();
    this.getTestiData();
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    theFormat(number) {
      let val = number.toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (window.innerWidth > 960) {
        if (window.innerHeight < 900) {
          if (window.scrollY > 900) {
            this.$refs.number1.play();
            this.$refs.number2.play();
            this.$refs.number3.play();
            this.$refs.number4.play();
          }
        }

        if (window.innerHeight >= 900) {
          if (window.scrollY > 550) {
            this.$refs.number1.play();
            this.$refs.number2.play();
            this.$refs.number3.play();
            this.$refs.number4.play();
          }
        }
      } else {
        if (window.innerHeight < 900) {
          if (window.scrollY > 600) {
            this.$refs.numberMobile1.play();
            this.$refs.numberMobile2.play();
            this.$refs.numberMobile3.play();
            this.$refs.numberMobile4.play();
          }
        }

        if (window.innerHeight >= 900) {
          if (window.scrollY > 550) {
            this.$refs.numberMobile1.play();
            this.$refs.numberMobile2.play();
            this.$refs.numberMobile3.play();
            this.$refs.numberMobile4.play();
          }
        }
      }
    },

    headwayTest: function () {
      window.open("https://headwaytest.com/", "_blank");
    },

    headwayIt() {
      this.$root.$emit("notHomeNavbar");
      this.$router.push("/products/headway-it-service");
    },

    async getHomeData() {
      this.info = await JSON.parse(localStorage.getItem("dataDescription"));
    },

    async getFormerData() {
      this.ourFormerData = await JSON.parse(localStorage.getItem("dataFormer"));
    },

    async getTestiData() {
      this.testiData = await JSON.parse(localStorage.getItem("dataClient"));
    },
    openChat() {
      document.querySelector(".woot--bubble-holder").click();
    },
  },
};
</script>

<style scoped>
@import "../styles/css/style.css";

.slick-slide {
  margin-left: 270px !important;
}

.slick-list {
  margin-left: 270px !important;
}

.btn-primary {
  width: 191px !important;
}

.bg2 {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/background/Rectangle 46.svg") center center
    no-repeat;
  background-size: cover;
}

.bg3 {
  width: 622px;
  height: 250px !important;
  background: linear-gradient(
    294.78deg,
    rgba(255, 255, 255, 0.2) 65.67%,
    rgba(255, 193, 7, 0.2) 112.32%
  );
  background-color: #dff1ff;
}

.bg4 {
  width: 100%;
  height: 526px;
  background: url("../assets/background/Rectangle 42.png") center center
    no-repeat;
  background-size: cover;
}

.testi-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #004d93;
}

.testi-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3f85d3;
}

.testi-text {
  line-height: 139%;
}

.header-title {
  font-weight: 700 !important;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  align-items: center;
  margin-top: 80px;

  color: #ffffff;
}

.sub-header-title {
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 27px !important;

  color: #00114c;
}

.sub-header-subtitle {
  font-weight: bold !important;
  font-size: 25px;
  line-height: 30px;
}

.img-header {
  margin: 20px -90px 0px 0px;
  float: right;
  max-width: 510px !important;
  max-height: 468px !important;
}

.btn-contact {
  width: 246px;
  height: 54px;
  border: 1px solid #ffffff !important;
  background: transparent !important;
  border-radius: 32px !important;
  color: #ffffff;
  text-transform: none !important;

  font-weight: 600 !important;
}

.btn-contact:hover {
  background: #ffffff !important;
  color: #3f85d3 !important;
}

.btn-xl {
  width: 200px !important;
  height: 56px !important;
  border-radius: 24px !important;
  background: #0369b3;
}

.text-xl {
  font-size: 20px !important;
}

.line-left {
  border-left: 1px solid #00114c;
}

.line-right {
  border-right: 1px solid #00114c;
}

.bg-our-former {
  padding: 50px 0px 0px 0px;
  object-fit: contain !important;
}

.d-small {
  display: none !important;
}

.d-large {
  display: block !important;
}

.product-small {
  display: none !important;
}

.product-large {
  display: block !important;
}

.former-spacer {
  margin-bottom: 56px;
  margin-top: 150px !important;
}

.text-question {
  text-align: justify !important;
}

.text-sub-header {
  line-height: 28px;
}

.bg_home {
  padding-top: 110px;
}

.border-number-right {
  border-right: 1px solid #e1e1e1;
}

div >>> .v-slide-group__next .v-icon {
  color: white;
  min-width: 72px !important;
}

div >>> .v-slide-group__prev .v-icon {
  color: white;
  min-width: 72px !important;
}
div >>> .v-slide-group__next {
  cursor: default !important;
  background-color: transparent !important;
}

div >>> .v-slide-group__prev {
  cursor: default !important;
  background-color: transparent !important;
  z-index: 30 !important;
}

.btn-arrow-testi {
  height: 50px;
  width: 50px;
}

div >>> .v-slide-group__prev--disabled .btn-arrow-testi {
  display: none !important;
}
div >>> .v-slide-group__next--disabled .btn-arrow-testi {
  display: none !important;
}

@media only screen and (min-width: 1904px) {
  .img-header {
    margin: 0px 0px;
    max-width: 610px !important;
    max-height: 568px !important;
  }

  .container-xl {
    padding: 0px 250px;
  }

  .bg_home {
    margin: -200px 0px;
    padding: 100px 0px 0px 0px;
  }

  .header-content {
    margin: 200px 0px 0px 0px;
  }

  .bg-our-former {
    margin: 300px 0px !important;
    padding: 0px;
    height: 500px;
    object-fit: contain !important;
  }

  .text-question {
    padding-right: 200px;
  }

  .former-spacer {
    margin-bottom: 56px;
    margin-top: 50px !important;
  }

  div >>> .v-slide-group__next {
    min-width: 80px !important;
  }

  div >>> .v-slide-group__prev {
    min-width: 80px !important;
  }
}

@media only screen and (max-width: 1263px) {
  .img-header {
    margin: 0px -110px;
    max-width: 450px !important;
  }

  .text-lg {
    font-size: 16px !important;
  }

  h1 {
    font-size: 60px;
  }

  h3 {
    font-size: 47px;
  }

  .testi-text {
    font-size: 14px;
  }

  .text-sub-header {
    padding-right: 40px;
  }

  .header-title {
    margin-top: 45px;
  }

  h3 {
    text-align: center;
  }

  .text-question {
    text-align: center !important;
  }

  .btn-contact {
    float: none !important;
  }

  .border-number-right {
    border-right: none;
  }
}

@media only screen and (max-width: 1080px) {
  .img-header {
    display: none;
  }

  .bg_home {
    height: 800px;
  }

  .d-large {
    display: none !important;
  }

  .d-small {
    display: block !important;
  }

  div >>> .slick-slide {
    padding-left: 40px !important;
  }

  .former-spacer {
    margin-bottom: 50px;
  }

  h3 {
    text-align: center;
  }

  .text-question {
    text-align: center !important;
  }

  .btn-contact {
    float: none !important;
  }

  .carousel-testi-small {
    height: 230px !important;
  }
}

@media only screen and (max-width: 960px) {
  .product-large {
    display: none !important;
  }

  .product-small {
    display: block !important;
  }

  .former-spacer {
    margin-bottom: 30px;
    margin-top: 0px !important;
  }

  h3 {
    text-align: center;
  }

  .btn-contact {
    float: none !important;
  }

  .header-title {
    margin-top: 0px;
  }

  .customer-card-small {
    padding: 20px 32px !important;
    height: 200px !important;
  }

  .carousel-testi-small {
    height: 200px !important;
  }

  .customer-img-small {
    height: 155px !important;
    width: 155px !important;
  }

  .img-former {
    height: 42px;
    width: 42px;
  }
}

@media only screen and (max-width: 600px) {
  .header-title {
    font-size: 25px;
    line-height: 35px;
  }

  .sub-header-title {
    font-size: 16px !important;
    line-height: 15px !important;
  }

  .text-sub-header {
    padding: 0px !important;
    font-size: 16px !important;
    line-height: 23px;
    font-weight: 300 !important;
    letter-spacing: 0.5px !important;
  }

  .btn-xl {
    margin-top: 0px !important;
    width: 140px !important;
    height: 42px !important;
    font-size: 14px !important;
  }

  .bg_home {
    height: 680px;
  }

  .product-desc-xsmall {
    line-height: 27.61px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .former-spacer {
    margin-top: 20% !important;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .bg-our-former {
    padding: 0px;
    height: 500px;
    object-fit: contain;
    background: url("../assets/background/Rectangle 46sm.svg") center center
      no-repeat;
  }

  .customer-card-small {
    padding: 20px 16px !important;
    height: 220px !important;
  }

  .d-small {
    display: none !important;
  }

  .testi-title {
    font-size: 16px;
    margin-bottom: 4px !important;
    line-height: 18px;
  }

  .testi-subtitle {
    font-size: 14px;
    margin-bottom: 8px !important;
    line-height: 16px;
  }

  .testi-text {
    font-size: 14px;
    margin-bottom: 0;
  }

  .carousel-testi-small {
    height: 250px !important;
  }

  .btn-arrow-testi {
    height: 40px;
    width: 40px;
  }

  h3 {
    font-size: 36px;
    line-height: 50px;
  }

  .text-question {
    padding: 0px !important;
    font-size: 14px !important;
    text-align: center !important;
    line-height: 20px !important;
  }

  .btn-contact {
    margin: 0px 0px;
  }

  .test-space {
    margin-top: 100px !important;
  }

  .bg3 {
    max-width: 100%;
    height: 300px !important;
    background-image: linear-gradient(
      294.78deg,
      rgba(255, 255, 255, 0.2) 65.67%,
      rgba(255, 193, 7, 0.2) 112.32%
    );
    background-color: #dff1ff;
  }

  div >>> .slick-slide {
    padding-left: 0px !important;
    padding-right: 20px !important;
  }

  div >>> .slick-dots li {
    width: 10px;
  }
}

@media only screen and (max-width: 360px) {
  .btn-xl {
    margin-top: 0px !important;
    width: 105px !important;
    height: 32px !important;
    font-size: 10px !important;
  }

  .bg_home {
    padding-top: 60px;
    height: 600px;
    width: 100%;
  }

  .product-desc-xsmall {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .former-spacer {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0px;
  }

  .text-counter-up {
    font-size: 12px !important;
    margin-bottom: 0px;
  }

  .text-counter-bt {
    font-size: 13px !important;
    margin-top: 4px !important;
  }

  .customer-card-small {
    padding: 20px 16px !important;
    height: 170px !important;
  }

  .btn-learn-more {
    width: 116px !important;
    height: 28px !important;
    font-size: 10px !important;
  }

  .carousel-testi-small {
    height: 170px !important;
  }

  h3 {
    font-size: 26px;
    line-height: 35px;
  }

  .btn-contact {
    margin: 0px 0px;
    width: 124px;
    height: 36px !important;
    font-size: 12px;
  }

  .test-space {
    margin-top: 100px !important;
  }

  .bg4 {
    width: 100%;
    height: 426px;
  }

  .btn-arrow-testi {
    width: 32px !important;
    height: 32px !important;
  }

  .testi-title {
    line-height: 22px;
  }

  .bg3 {
    max-width: 100%;
    height: auto !important;
  }
}
</style>
