<template>
  <div id="main" class="mt-md-16 pt-10">
    <v-container class="mt-4 pt-10 container-xl mb-16 pb-16">
      <v-row>
        <v-col cols="12" lg="6" md="12" xl="6" xs="12" class="mt-10">
          <p class="title-about">Head Office</p>
          <p class="desc-about">
            Jl. Ahmad Yani Utara 484, Denpasar, Bali, 80115
          </p>
        </v-col>
        <v-col cols="12" lg="6" md="12" xl="6" xs="12" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                width="561px"
                height="322px"
                class="img-maps pointer"
                alt="click to open map"
                :src="require('../assets/background/map.svg')"
                @click="openMaps"
              ></v-img>
            </template>
            <span>Click to open map</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

export default {
  name: "FindUs",
  components: {
    Footer,
  },
  data() {
    return {
      branch: [],
      load: true,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    openMaps: function() {
      window.open(
        "https://www.google.com/maps/place/PT.+HEADWAY/@-8.5978297,115.2147183,15z/data=!4m5!3m4!1s0x0:0x42bd7765aa9b98c4!8m2!3d-8.5978297!4d115.2147183",
        "_blank"
      );
    },

    openBranchMaps(link) {
      window.open(link, "_blank");
    },

    async getData() {
      const dataApiBranch = JSON.parse(localStorage.getItem("dataBranch"));
      for (var i = 0; i < dataApiBranch.length; i++) {
        this.branch[i] = dataApiBranch[i];
      }

      this.load = false;
    },
  },
};
</script>

<style scoped>
@import "../styles/css/style.css";

.title-about {
  font-family: Montserrat !important;
  font-weight: bold;
  font-size: 31px;
  line-height: 38px;
}
.desc-about {
  font-weight: normal;
  font-size: 14px;
  line-height: 136.4%;
}

.subtitle-about {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #00114c;
}

.card-about {
  width: 328px;
  height: 119px;
  padding: 20px 24px 24px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) !important;
  border-radius: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
}

.img-maps {
  border-radius: 4px;
}

@media only screen and (min-width: 1904px) {
  .container-xl {
    padding: 0px 250px;
  }
}

@media only screen and (max-width: 600px) {
  .card-about {
    margin-left: 12px;
    margin-bottom: 24px;
  }
}
</style>
