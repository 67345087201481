<template>
  <v-app>
    <div v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <Navbar />
      <v-main style="padding-top: 0px !important">
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Loading from "./components/Loading.vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    Navbar,
    Loading,
  },

  data: () => ({
    loading: false,
    isExpired: false,
  }),

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "CV. REKSA";
      },
    },
  },

  mounted() {
    if (localStorage.checkLocal) {
      this.isExpired = this.checkExpired();
      if (this.isExpired) {
        this.setLoading();
        this.getDataFromAPI();
        console.log("expired");
      }
      this.startChat();
    } else {
      this.setLoading();
      this.getDataFromAPI();
      this.startChat();
    }
  },

  methods: {
    setLoading() {
      this.loading = !this.loading;
    },
    async getDataFromAPI() {
      const auth = "Bearer ".concat(process.env.VUE_APP_TOKEN);
      const url = process.env.VUE_APP_SERVE_URL + "/api";
      const description = "/description";
      const former = "/our-former-client";
      const testi = "/love-from-customer";
      const branch = "/branch-office";
      const date = new Date();

      // description
      await axios
        .get(url + description, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          localStorage.setItem(
            "dataDescription",
            JSON.stringify(response.data)
          );
        });

      // our former
      await axios
        .get(url + former, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          localStorage.setItem("dataFormer", JSON.stringify(response.data));
        });

      // testi
      await axios
        .get(url + testi, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          localStorage.setItem("dataClient", JSON.stringify(response.data));
        });
      // branch
      await axios
        .get(url + branch, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          localStorage.setItem("dataBranch", JSON.stringify(response.data));
        });
      localStorage.checkLocal = true;
      localStorage.setItem("expiry", date.getTime());
      this.setLoading();
    },
    checkExpired() {
      const expired = localStorage.expiry;
      const date = new Date();
      let hours = 24;
      if (date.getTime() - expired > hours * 60 * 60 * 1000) {
        localStorage.removeItem("dataDescription");
        localStorage.removeItem("dataFormer");
        localStorage.removeItem("dataClient");
        localStorage.removeItem("dataBranch");
        localStorage.removeItem("checkLocal");
        localStorage.removeItem("expiry");
        return true;
      }
    },
    startChat() {
      (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'SaSwibuDaE1hRRQWhSw8feu5',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    },
  },
};
</script>
