import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products/Main.vue'
import About from '../views/About.vue'
import FindUs from '../views/FindUs.vue'
import Donya from '../views/Products/Donya.vue'
import Sid from '../views/Products/SID.vue'
import Manpro from '../views/Products/Manpro.vue'
import NotFound from '../views/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/products/it-service',
    name: 'Headway IT Service',
    component: Products,
    meta: {title: 'HeadWay IT Service | CV. REKSA'}
  },
  {
    path: '/products/it-service/donya',
    name: 'Donya',
    component: Donya,
    meta: {title: 'Donya | CV. REKSA'}
  },
  {
    path: '/products/it-service/implementation-of-sistem-informasi-desa',
    name: 'SID',
    component: Sid,
    meta: {title: 'Implementation of "Sistem Informasi Desa" | CV. REKSA'}
  },
  {
    path: '/products/it-service/manpro',
    name: 'Project Management',
    component: Manpro,
    meta: {title: 'MANPRO | CV. REKSA'}
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {title: 'About | CV. REKSA'}
  },
  {
    path: '/find-us',
    name: 'Find Us',
    component: FindUs,
    meta: {title: 'Find Us | CV. REKSA'}
  },
  {
    path: '*',
    name: 'Error',
    component: NotFound,
    meta: {title: '404 Not Found'}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
